<template>
  <div class="content-box-padding">
    <div class="sub-title">技术手段</div>
    <div class="line-text" style="margin-top: 20px">
      常规统计分析工具：诸如IGOR Pro、Matlab、Origi、NCL、R语言等绘图软件；<br />
      卫星遥感反演：对流层NO2、HCHO、O3、CO、NH3、SO2等气态污染物浓度、气溶胶、沙尘等颗粒物反演，以及火点分布等；<br />
      空气质量数值模型模拟：以CMAQ、CAMx、WRF-Chem、LPDM等第三代空气质量模型为核心，实现污染时空回溯、立体感知及精准溯源。<br />
    </div>
    <img
      src="~assets\image\business\技术手段.png"
      alt="技术手段"
      style="margin: 50px auto 0px auto"
    />
  </div>
</template>
